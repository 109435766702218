import { Component, computed, inject, signal } from "@angular/core"
import { FormsModule } from "@angular/forms"
import { UserLoginService } from "./user-login.service"
import { UiDialogComponent } from "ui/dialog"
import { Auth, sendPasswordResetEmail } from "@angular/fire/auth"

@Component({
  imports: [
    FormsModule,
    UiDialogComponent,
  ],
  standalone: true,
  template: `
    <div class="column">
      <div>
        <div class="column">
          <div class="row" style="justify-content: center">
            <button
              class="btn rounded-b-none"
              [class]="newAccountButtonClass()"
              (click)="createNewAccount()"
            >
              Create New Account
            </button>
            <button
              class="btn rounded-b-none"
              [class]="existingAccountButtonClass()"
              (click)="useExistingAccount()"
            >
              Use Existing Account
            </button>
          </div>
        </div>
        <div class="column border-4 border-solid border-accent rounded-b-lg">
          <div class="center">
            @if (newAccount()) {
              Create New Account
            }
            @if (!newAccount()) {
              Sign in with Existing Account
            }
          </div>
          <input
            class="input"
            placeholder="email address"
            type="email"
            [(ngModel)]="email"
          />

          <div>
            <input
              class="input"
              placeholder="password"
              style="width: 100%"
              [type]="passwordVisible ? 'text' : 'password'"
              [(ngModel)]="password"
            />
            <div
              class="center"
              style="color: #1890ff"
              (click)="passwordVisible = !passwordVisible"
            >
              {{ !passwordVisible ? "show password" : "hide password" }}
            </div>
          </div>

          @if (email && password) {
            <div class="center">
              <button
                class="btn"
                [disabled]="!email || !password"
                (click)="login()"
              >
                Sign In
              </button>
            </div>
          }
        </div>
      </div>

      <div class="center">
        <button
          class="btn btn-primary"
          (click)="showPasswordReset.set(true)"
        >
          Forgot Password?
        </button>
      </div>
    </div>

    @if (showPasswordReset()) {
      <lib-ui-dialog
        (cancelAndClose)="cancelPasswordReset()"
      >
        <div ngProjectAs="title">
          Password Reset
        </div>

        <div ngProjectAs="content">
          <div class="column-center">
            <input
              class="input"
              placeholder="email address"
              type="email"
              [(ngModel)]="email"
            />

            <button
              class="btn btn-primary"
              (click)="sendEmail(email)"
            >
              Send Password Reset Email
            </button>

          </div>
        </div>

      </lib-ui-dialog>
    }
  `,
  selector: "e2e-sign-in-with-password",
})
export class UserSignInWithPasswordComponent {
  private loginService = inject(UserLoginService)
  private auth = inject(Auth)

  password = ""
  passwordVisible = false
  email = ""
  newAccount = signal(true)
  newAccountButtonClass = computed(() => this.newAccount() ? "btn-primary" : "")
  existingAccountButtonClass = computed(() => !this.newAccount() ? "btn-primary" : "")
  showPasswordReset = signal(false)

  login() {
    if (this.email && this.password) {
      this.loginService.withPassword(this.email, this.password)
      // switch (this.newAccount) {
      //   case true:
      //     this.userAuthService.userCreateUserWithEmailAndPassword(this.email, this.password)
      //     break
      //   case false:
      //     this.userAuthService.userSignInWithEmailAndPassword(this.email, this.password)
      // }
    }
  }

  createNewAccount() {
    this.newAccount.set(true)
  }

  useExistingAccount() {
    this.newAccount.set(false)
  }

  cancelPasswordReset() {
    this.showPasswordReset.set(false)
  }

  sendEmail(email: string) {
    sendPasswordResetEmail(this.auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        console.log(error)
        const errorCode = error.code
        const errorMessage = error.message
        // ..
      })
  }
}
