import { computed, effect, inject, Injectable, signal } from "@angular/core"
import {
  Auth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signInWithPopup,
  UserCredential,
} from "@angular/fire/auth"
import { toSignal } from "@angular/core/rxjs-interop"
import { from } from "rxjs"
import { ProviderType, UtilAuthProviderService } from "util/auth-provider"

@Injectable({
  providedIn: "root",
})
export class UserLoginService {
  private auth = inject(Auth)
  private authProviderService = inject(UtilAuthProviderService)

  providerTypes = signal<ProviderType[]>([
    // ProviderType.APPLE,
    // ProviderType.FACEBOOK,
    ProviderType.GOOGLE,
    // ProviderType.GITHUB,
    // ProviderType.MICROSOFT,
    // ProviderType.TWITTER
    ProviderType.PASSWORD,
  ])
  providerTypesText = computed(() => {
    const providerTypes = this.providerTypes()
    if (providerTypes.length) {
      return "(" +
        this.providerTypes()
          .filter(providerType => providerType !== ProviderType.PASSWORD)
          .map(providerType => this.authProviderService.providers_map().get(providerType)?.label)
          .filter(Boolean)
          .join(", ")
        + ")"
    }
    return ""
  })

  signInSuccess = signal(false)
  signInWithPopupFailure = signal(false)
  signOutSuccess = signal(false)
  signOutFailure = signal(false)

  withPassword_flag = signal(false)

  authStateReady = toSignal(from(this.auth.authStateReady()))

  // authStateChange = toSignal(new Observable((observer) =>
  //   this.auth.onAuthStateChanged(observer.next, observer.error, observer.complete),
  // ))

  // idTokenChanged = toSignal(new Observable((observer) =>
  //   this.auth.onIdTokenChanged(observer.next, observer.error, observer.complete),
  // ))

  constructor() {
    effect(() => {
      // console.log(this.authStateReady())
      // console.log(this.auth.currentUser)
    })
    effect(() => {
      // console.log(this.authStateChange())
    })
    effect(() => {
      // console.log(this.idTokenChanged())
    })
  }

  signOut() {
    this.auth.signOut()
      .then(() => {
        this.signOutSuccess.set(true)
        this.signOutFailure.set(false)
      })
      .catch(error => {
        console.log("userSignOut error", error)
        this.signOutSuccess.set(false)
        this.signOutFailure.set(true)
      })
  }

  withPopup(providerType: ProviderType) {
    console.log(providerType)
    const provider = this.authProviderService.create(providerType)
    if (provider) {
      signInWithPopup(this.auth, provider)
        .then(userCredential => {
          console.log(userCredential)
          this.signInSuccess.set(true)
          this.signInWithPopupFailure.set(false)
        })
        .catch(error => {
          console.log("signInWithPopup error", error)
          this.signInSuccess.set(false)
          this.signInWithPopupFailure.set(true)
        })
    }
    if (!provider) {
      console.log("signInWithPopup Provider is not available")
      this.signInSuccess.set(false)
      this.signInWithPopupFailure.set(true)
    }
  }

  withPassword(email: string, password: string) {
    if (!email || !password) {
      console.log("missing email or password")
    }
    if (email && password) {
      signInWithEmailAndPassword(this.auth, email, password)
        .then((userCredential) => {
          console.log("logged in success")
          this.signInSuccess.set(true)
          /*
                       if (userCredential.user.emailVerified) {
                         console.log("logged in success")
                       }
                       if (!userCredential.user.emailVerified) {
                         this.emailVerificationSend(userCredential)
                       }
             */
        })
        .catch(error => {
          /**
           * see error codes
           * https://firebase.google.com/docs/auth/admin/errors
           */
          const errorCode = error.code
          const errorMessage = error.message

          console.log("withPassword", errorCode + " - " + errorMessage)

          switch (errorCode) {
            case "auth/user-not-found":
            case "auth/invalid-credential":
              this.createUser(email, password)
              break
            case "auth/invalid-email":
              alert("invalid email")
              break
            case "auth/user-disabled":
              alert("unknown error")
              break
            case "auth/wrong-password":
              alert("Wrong password or wrong provider. Try changing the password or a different Provider. " + this.providerTypesText())
              break
            default:
              alert("unknown error: " + errorCode + " - " + errorMessage)
          }
        })
    }
  }


  createUser(email: string, password: string) {
    if (!email || !password) {
      console.log("missing email or password")
    }
    if (email && password) {
      createUserWithEmailAndPassword(this.auth, email, password)
        .then((userCredential) => {
          console.log("logged in success")
          this.signInSuccess.set(true)
          /*
                        if (userCredential.user.emailVerified) {
                          console.log("logged in success")
                        }
                        if (!userCredential.user.emailVerified) {
                          this.emailVerificationSend(userCredential)
                        }
              */
        })
        .catch(error => {
          const errorCode = error.code
          const errorMessage = error.message
          switch (errorCode) {
            case "auth/weak-password":
              alert("The password is too weak.")
              break
            case "auth/email-already-in-use":
              alert("email is already used, please try a different login method (Google)")
              break
            default:
              console.log("userCreateUserWithEmailAndPassword", errorCode)
              alert("unknown error: " + errorCode + " - " + errorMessage)
          }

        })
    }
  }

  sendVerificationEmail(userCredential: UserCredential) {
    sendEmailVerification(userCredential.user, { url: location.href, handleCodeInApp: true })
      .then(() => {
        console.log("emailVerificationSendSuccess")
      })
      .catch((error) => {
        const errorCode = error.code
        const errorMessage = error.message
        switch (errorCode) {
          case "auth/too-many-requests":
            alert("please wait a minute and try again")
            break
          default:
            alert("unknown error: " + errorCode + " - " + errorMessage)
        }
        alert("all errors: " + errorCode + " - " + errorMessage)
        console.log("emailVerificationSendFailure")
      })
  }

  /*
    signOutAfterSendingEmailVerification() {
      this.auth.signOut()
        .then(() => {
          console.log("signOut")
        })
        .catch((error) => {
          console.log("error")
          console.log(error)
        })
    }
  */

}
