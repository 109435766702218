import { Component, inject } from "@angular/core"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { StripeService } from "ngx-stripe"
import { Stripe } from "stripe"
import { catchError, switchMap } from "rxjs/operators"
import { EMPTY, of } from "rxjs"
import { CheckoutService, CheckoutSessionLineItem } from "./checkout.service"
import { environment } from "../../../environments/environment"

@Component({
  imports: [],
  standalone: true,
  template: `
      <button
        class="btn btn-success btn-sm"
        [disabled]="!checkoutLineItems().length"
        (click)="checkout(customerId(), checkoutLineItems())"
      >
        Checkout
        <div>
          {{ checkoutLineItems().length.toString() }}
        </div>
      </button>
  `,
  selector: "e2e-checkout",
})
export class CheckoutComponent {
  private cartService = inject(CheckoutService)
  private httpClient = inject(HttpClient)
  private stripeService = inject(StripeService)

  checkoutLineItems = this.cartService.checkoutLineItems
  customerId = this.cartService.customerId

  /**
   * currently requires user login
   * TODO: add guest user customerId
   *
   * looks like we need to add a CustomerDoc doc to "customers" and populate the afUser variable
   */
/*
  customerId$ = toObservable(this.afUser)
    .pipe(
      switchMap(user => {
        if (user?.uid) {
          return from(getDoc(doc(this.firestore, "customers", user.uid)))
            .pipe(
              map(snapshot => {
                if (snapshot.exists()) {
                  const firestoreCustomer = snapshot.data() as CustomerDoc
                  return firestoreCustomer.stripeId
                }
                return null
              }),
              catchError(() => of(undefined)),
            )
        }
        return of(undefined)
      }),
    )
*/

  checkout(customerId: string | undefined, checkoutLineItems: CheckoutSessionLineItem[]) {
    if (checkoutLineItems.length) {
      const baseUrl = environment.baseUrl
      let sessionParams: Stripe.Checkout.SessionCreateParams = {
        // billing_address_collection: "required",
        automatic_tax: {
          enabled: true,
        },
        custom_fields: [
          {
            key: "specialInstructions",
            label: {
              type: "custom",
              custom: "Special Instructions",
            },
            optional: true,
            type: "text",
          },
        ],
        // customer_creation: "always",
        // customer_email: "lowell@digitalfarrier.com",
        line_items: checkoutLineItems,
        mode: "payment",
        payment_method_types: ["card"],
        phone_number_collection: {
          enabled: true,
        },
        cancel_url: baseUrl + location.pathname,
        success_url: baseUrl + "/success",
        shipping_address_collection: {
          allowed_countries: ["US"],
        },
        // shipping_options: Populated in Cloud Function from GCP Secret Manager as stripeShippingRate [{
        //   // shipping_rate: "shr_1Q08dWF292Q2ICNoRL4Evt3w", // test
        //   // shipping_rate: "shr_1Q06bkF292Q2ICNoSIV8miqR", // live
        // }],
        // return_url: environment.hostingUrl + "/checkout",
        // tax_rates: [TAX_RATE_ID],
        // dynamic_tax_rates: [DYNAMIC_TAX_RATE_ID],
        ui_mode: "hosted",
      }
      if (customerId) {
        sessionParams = {
          ...sessionParams,
          customer: customerId,
          customer_update: {
            address: "auto",
            name: "auto",
            shipping: "auto",
          },

        }
      }
      const httpPostBody = {
        headers: new HttpHeaders({}),
        sessionParams,
      }
      // console.log(httpPostBody)
      this.httpClient
        .post<Stripe.Checkout.Session>(baseUrl + "/api-v1/create-checkout-session", httpPostBody)
        .pipe(
          switchMap((session) => {
            if (session.id) {
              return this.stripeService.redirectToCheckout({ sessionId: session.id })
            }
            return of({ error: { message: "session id is missing from Stripe.Checkout.Session cloudFunction" } })
          }),
          catchError((err) => {
            console.error(err)
            return EMPTY
          }),
        )
        .subscribe((result) => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          if (result?.error) {
            alert(result.error.message)
          }
        })
    }
  }
}
