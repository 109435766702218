import { Component } from "@angular/core"
import { FirebaseAuthUserManagerComponent } from "../../data-access/firebase-auth-user-manager.component"

@Component({
  imports: [
    FirebaseAuthUserManagerComponent,
  ],
  standalone: true,
  template: `
      <e2e-user-manager/>
  `,
  selector: "e2e-users",
})
export class UsersComponent {

}
