import { Component, computed, effect, inject, input } from "@angular/core"
import { ImportComponent } from "../import/import.component"
import { UiInheritDirective } from "ui/inherit"
import { ProviderType } from "util/auth-provider"
import { GroupsService } from "../products/groups/groups.service"
import { JsonPipe } from "@angular/common"
import { FirebaseAuthService } from "../../data-access/firebase-auth.service"
import { UserLoginService } from "../../user/user-login.service"
import { UserLoginComponent } from "../../user/user-login.component"

@Component({
  imports: [
    UiInheritDirective,
    ImportComponent,
    JsonPipe,
    UserLoginComponent,
  ],
  standalone: true,
  template: `
    <div class="column">
      <e2e-user-login/>
      @if (loggedIn() && isAdmin()) {
        <e2e-import inherit/>
        {{ afUser() | json }}
      }
    </div>
  `,
  selector: "e2e-login",
})
export class LoginComponent {
  private groupService = inject(GroupsService)
  private loginService = inject(UserLoginService)
  private firebaseAuthService = inject(FirebaseAuthService)

  loggedIn = computed(() => !!this.firebaseAuthService.afUser())
  afUser = computed(() => this.firebaseAuthService.afUser())

  providerTypes = input<ProviderType[]>([
    // ProviderType.APPLE,
    // ProviderType.FACEBOOK,
    ProviderType.GOOGLE,
    // ProviderType.GITHUB,
    // ProviderType.MICROSOFT,
    // ProviderType.TWITTER
    ProviderType.PASSWORD,
  ])

  afUserEmail = this.firebaseAuthService.afUserEmail
  isAdmin = computed(() =>
    this.afUserEmail() === "lowell@digitalfarrier.com" ||
    this.afUserEmail() === "robertduggan2023@gmail.com" ||
    this.afUserEmail() === "robertduggan@rtduggan.com"
  )

  constructor() {
    this.loginService.providerTypes.set(this.providerTypes())
    /**
     * scroll to top when component loads
     * but not on initial app-load
     * only when clicking menu link for this route
     * whether on this route already or navigating to it
     */
    effect(() => {
      if (this.groupService.scrollToTop()) {
        // scrollToTop("smooth", 0, 0)
      }
    })
  }
}
