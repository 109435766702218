import { inject, Injectable } from "@angular/core"
import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http"
import { Observable } from "rxjs"
import { FirebaseAuthService } from "./firebase-auth.service"

@Injectable({ providedIn: "root" })
export class AuthTokenHttpInterceptor implements HttpInterceptor {
  private authStateService = inject(FirebaseAuthService)

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let clone = req.clone()
    const token = this.authStateService.idTokenResultWithClaims()?.token
    if (token) {
      clone = req.clone({ headers: req.headers.set("Authorization", "Bearer " + token) })
      return next.handle(clone)
    }
    return next.handle(req)
    // return throwError(() => {
    //   console.log(req)
    //   return "AuthTokenHttpInterceptor has undefined idTokenResult()?.token"
    // })

  }
}

export const AuthTokenHttpInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthTokenHttpInterceptor,
  multi: true
}
