import { Component } from "@angular/core"
import { NgOptimizedImage } from "@angular/common"

@Component({
  imports: [
    NgOptimizedImage,
  ],
  standalone: true,
  template: `
    <a href="https://mnschoolofhorseshoeing.com">
      <div class="column gap-10">
        <div class="row-center gap-16 pt-10">
          <div>
            <img
              style="max-width: 400px"
              alt=""
              ngSrc="assets/minnesota-school-of-horseshoeing-logo.jpg"
              height="122"
              width="400"
              priority=""
            />
          </div>
        </div>

        <h2 class="center text-3xl font-bold">
          Minnesota School of Horseshoeing
        </h2>
      </div>
    </a>

    <!--
    <div>
      We also operate the Minnesota School of Horseshoeing where we teach beginning students the groundwork for
      becoming a successful farrier. Check out the school website if you are interested in attending our 12- or
      24-week professional program.
    </div>
-->
  `,
  selector: "e2e-school",
})
export class SchoolComponent {

}
